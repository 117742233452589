import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import React, { FC } from "react";
type Props = {
  children: React.ReactNode;
};
export const CPEGTMProvider: FC<Props> = props => {
  const { children } = props;
  const id = window.__config__?.gtmKey || "";

  if (!id) {
    return <>{children}</>;
  }
  const gtmParams = { id };
  return <GTMProvider state={gtmParams}>{children}</GTMProvider>;
};
