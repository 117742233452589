import { Course } from "@cpe/models/course";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OthersAlsoPlayedCourses, SimilarCourses } from "./thunks";

export interface CoursesState {
  savedItemsIds: string[];
  filteredCourses: { [key: string]: Course };
  similarCoursesIds: string[];
  othersAlsoPlayedCoursesIds: string[];
  loading: boolean;
  error: string | null;
}
export const initialState: CoursesState = {
  savedItemsIds: [],
  filteredCourses: {},
  similarCoursesIds: [],
  othersAlsoPlayedCoursesIds: [],
  loading: false,
  error: null
};

export const recommendedCoursesSlice = createSlice({
  name: "recommendedCourses",
  initialState: initialState,
  reducers: {
    addSavedItem: (state, action: PayloadAction<string>) => {
      state.savedItemsIds = [action.payload, ...state.savedItemsIds];
      state.filteredCourses[action.payload].state.saved = true;
    },
    removeSavedItem: (state, action: PayloadAction<string>) => {
      state.savedItemsIds = [...state.savedItemsIds.filter(productId => productId !== action.payload)];
      state.filteredCourses[action.payload].state.saved = false;
    },
    registerCourse: (state, action: PayloadAction<string>) => {
      state.filteredCourses[action.payload].actions = state.filteredCourses[action.payload].actions.filter(
        action => action !== "REGISTER"
      );
      state.filteredCourses[action.payload].actions.push("UNREGISTER");
      state.filteredCourses[action.payload].state.registered = true;
    }
  },
  extraReducers: builder => {
    builder.addCase(SimilarCourses.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.similarCoursesIds = [];
      } else {
        const courses = (action.payload as Course[]) || [];
        state.similarCoursesIds = [];
        courses.forEach(course => {
          state.filteredCourses[course.productId] = course;
          state.similarCoursesIds.push(course.productId);
        });
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(SimilarCourses.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(SimilarCourses.pending, state => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(OthersAlsoPlayedCourses.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.othersAlsoPlayedCoursesIds = [];
      } else {
        const courses = (action.payload as Course[]) || [];
        state.othersAlsoPlayedCoursesIds = [];
        courses.forEach(course => {
          state.filteredCourses[course.productId] = course;
          state.othersAlsoPlayedCoursesIds.push(course.productId);
        });
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(OthersAlsoPlayedCourses.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(OthersAlsoPlayedCourses.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export const { addSavedItem, removeSavedItem, registerCourse } = recommendedCoursesSlice.actions;
export default recommendedCoursesSlice.reducer;
